body {
    background-color: #130d24;
}
.mySwiper {
    margin: 0;
    padding: 0;
}
.swiper {
    background: #774040;
    margin: 0;
}
.swiper-slide {
  font-size: 18px;
  font-family: sfth-rg;
  background: inherit;
  color: white;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}


